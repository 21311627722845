/* Button Group Switch*/
.switch {
    display: inline-block;
  }
  .switch-button {
    background: var(--grey);
    display: grid;
    grid-template-columns: 1fr 1fr;
    color: #000;
    position: relative;
    cursor: pointer;
    outline: 0;
    user-select: none;
    border-radius: 80px;
    border: 1px solid rgba(35, 31, 32, 0.07);
    background: #fff;
    box-shadow: 0px 4px 14px 0px rgba(0, 0, 0, 0.04);
    padding: 4px;
  }
  .err_msg {
    text-align: start;
    display: block;
    padding: 5px 15px;
    color: red;
}
  .switch-input:not(:checked) + .switch-button .switch-button-left {
    background-color: var(--red);
    border-radius: 48px;
    color: #fff;
    text-align: center;
    font-family: Avenir;
    font-size: 14px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    display: flex;
    padding: 4px 24px;
    justify-content: center;
    align-items: center;
  }
  
  .switch-input {
    display: none;
  }
  
  .switch-button span {
    padding: 0.2rem 0.7rem;
    z-index: 2;
    transition: color 0.2s;
    color: var(--black, #231f20);
    text-align: center;
    font-family: Avenir;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  
  .switch-button::before {
    content: "";
    position: absolute;
    border-radius: 80px;
    top: 0;
    left: 0;
    height: 100%;
    width: 50%;
    z-index: 1;
    transition: left 0.3s cubic-bezier(0.175, 0.885, 0.32, 1), padding 0.2s ease,
      margin 0.2s ease;
  }
  
  .switch-button:hover::before {
    will-change: padding;
  }
  
  .switch-button:active::before {
    padding-right: 0.4rem;
  }
  
  .switch-input:checked + .switch-button .switch-button-right {
    background-color: var(--green);
    border-radius: 48px;
    color: var(--white);
    text-align: center;
    font-family: Avenir;
    font-size: 14px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    display: flex;
    padding: 4px 24px;
    justify-content: center;
    align-items: center;
  }
  
  .switch-input:checked + .switch-button::before {
    left: 50%;
  }
  .switch-input:checked + .switch-button:active::before {
    margin-left: -0.4rem;
  }
  
  .switch-input[type="checkbox"]:disabled + .switch-button {
    opacity: 0.6;
    cursor: not-allowed;
  }
  .delete-acc-link {
    color: var(--black, #231f20);
    text-align: center;
    font-family: Avenir;
    font-size: 14px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
  }
  .switch-toggle-head {
    min-width: 250px;
  }
  /* profile- table-css */
  
/* company-info */
.company-info-head {
  font-size: 22px;
}

.company-info-sub-head {
  color: #343a40;
  font-size: 18px;
}

.label-head{
  width: 45%;
}
.company-info-btns {
  display: inline-block;
}
.company-section-sub-head img{
  border-radius: 38px;
  border: 1px solid #231f202b;
  padding: 8px;
}
.user-professtion{
  color: var(--pink, #F949B2);
   font-family: Avenir;
  font-size: 14px;
  font-style: normal;
  font-weight: 900;
  text-align: start;
}

.new-info-btn {
  position: absolute;
  top: 19px;
  right: 28px;
}
.side-
 {
  padding: 10px 52px 10px 42px !important;
}
.role-list {
  border-radius: 283px;
  border: 1px solid rgba(35, 31, 32, 0.3);
  background: var(--secondary-theme);
  padding: 7px 15px;
  display: inline-block;
  margin-bottom: 10px;
  font-size: 14px;

}
.member-table th,
td {
  padding: 10px 15px !important;
}
.organization-table th{
  padding: 10px 15px !important;
}
.member-head {
  font-size: 22px;
}
.home-nav-link {
  color: var(--black);
}
.home-nav-link.active {
  color: unset !important;
  background-color: unset !important;
  border-color: unset !important;
  border: none !important;
  border-radius: 4px 4px 0px 0px;
  border-bottom: 4px solid var(--yellow);
}
.home-nav-link:hover {
  border-color: transparent !important;
}
.home-nav-link.active {
  border-bottom: 6px solid #fed71a !important;
}
.custom-nav-tab {
  border-bottom: 1px solid var(--primary-theme);
}
.list-category-left{
  width: 75%;
}
.one-line-text{
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
/* custom-check-box */
.sq-radio {
  display: block;
  position: relative;
  padding-left: 25px;
  margin: 4px 0px 0px 0px;
  cursor: pointer;
  font-size: 18px;
}

.sq-radio input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  width: 14px;
  height: 14px;
  border-radius: 4px;
  background-color: white;
  border: 2px solid var(--secondary-theme);
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.sq-radio input:checked ~ .checkmark:after {
  display: block;
}

.sq-radio .checkmark:after {
  top: 1px;
  left: 1px;
  width: 8px;
  height: 8px;
  border-radius: 2px;
  background-color: var(--secondary-theme);
}
.arrow-img{
  position: absolute;
  top: 18px;
  right: 20px;
}
.list-content-item.active {
  background: var(--black, #231F20);
  color: var(--white);
}
.list-content-item img{
margin-bottom: 3px;
}
.grey-change-btn {
  background: grey !important;
  cursor: not-allowed !important
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
}

.pac-container{
  z-index: 9999 !important;
}
