/* / billing-setting / */
.plan-arrow {
  position: absolute;
  top: 12px;
  right: 30px;
}

.plan-btn {
  color: var(--black);
  text-align: center;
  font-size: 20px;
  font-family: var(--font-family);
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  border-radius: 100px;
  border: 2px solid var(--fl-black);
  background: var(--yellow, #fed71a);
  display: inline-flex;
  padding: 8px 42px 8px 42px;
}

.plan-arrow {
  position: absolute;
  top: 16px;
  right: 34px;
}

.blue-plan-btn {
  background: var(--secondary-theme);
}

.blue-arrow {
  top: 12px;
}

.Plan-tag {
  border-radius: 90px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  background: #fff;
  display: inline-flex;
  height: 24px;
  padding: 8px 12px 7px 12px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
}

.paln-box-main {
  border-radius: 10px;
  background: #FFF;
  box-shadow: 0 0px 11px -2px #6ed7ef;
  padding: 10px;
  width: 387px;
  margin: auto;
}

.plan-box {
  border-radius: 10px;
  background: #dff3f7;
  padding: 10px 15px;
}

.plan-box-text {
  color: #8B8A8A;
}

.plan-price {
  font-size: 33px;
}

/* / custom-check-box / */
.plan-form-check-input:checked {
  background-color: #FED71A;
  border-color: #292D32;
}

.plan-form-check-input {
  width: 18px;
  height: 18px;
}

.plan-form-check-input:checked[type=checkbox] {
  background-image: url("../Images/check_box_tick.svg");
  background-repeat: no-repeat;
}

/* / credit-card / */
.credit-payment-text {
  color: var(--white);
  font-size: 34px;
}

.credit-payment {
  border-radius: 15px;
  background-color: #06408A;
  padding: 10px 10px 10px 30px;
}

.number-text {
  border-radius: 10px;
  color: transparent;
  text-shadow: -1px -2px 1px rgba(255, 255, 255, 1), 1px 1px 1px rgba(0, 0, 0, 0.9), 1px -1px 1px rgba(255, 255, 255, 1), -1px -1px 3px rgba(0, 0, 0, 0.9), -2px 2px 1px rgba(0, 0, 0, 0.9);
  -webkit-text-stroke: 2px rgba(0, 0, 0, 0.4);
  font-size: 42px;
  text-align: start;
  font-weight: 500;
}

.valid-text {
  color: var(--white);
  font-size: 14;
  line-height: 0px;
}

.valid-number {
  color: transparent;
  text-shadow: -1px -2px 1px rgba(255, 255, 255, 1), 1px 1px 1px rgba(0, 0, 0, 0.4), 1px -1px 1px rgba(255, 255, 255, 1), -1px -1px 3px rgba(0, 0, 0, 0.4), -2px 2px 1px rgba(0, 0, 0, 0.4);
  -webkit-text-stroke: 2px rgba(0, 0, 0, 0.4);
  font-size: 24px;
  text-align: start;
  font-weight: 500;
}

.valid-date {
  margin-left: 110px;
}
.credit-payment-new-method{
  visibility: hidden;
}
.customer-name {
  color: transparent;
  text-shadow: -1px -2px 1px rgba(255, 255, 255, 1), 1px 1px 1px rgba(0, 0, 0, 0.4), 1px -1px 1px rgba(255, 255, 255, 1), -1px -1px 3px rgba(0, 0, 0, 0.4), -2px 2px 1px rgba(0, 0, 0, 0.4);
  -webkit-text-stroke: 2px rgba(0, 0, 0, 0.4);
  font-size: 24px;
  text-align: start;
  font-weight: 500;
}

.custom-chip-fluid {
  max-width: 15%;
  height: auto;
}

.custom-fluid {
  max-width: 25%;
  height: auto;
}

.delete-card {
  color: var(--black);
  text-align: center;
  font-size: 14px;
  font-family: var(--font-family);
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  border-radius: 100px;
  border: 1px solid rgba(35, 31, 32, 0.30);
  background: transparent;
  display: inline-flex;
  padding: 8px 24px 8px 24px;
}

.add-card {
  border-radius: 10px;
  border: 2px dashed var(--blue, #6ED7EF);
  background: #EBFBFF;
  padding: 132px 132px;
}
.midsize-black{
  color: var(--black);
}
.large-blue{
color: var(--secondary-theme);
}
.enterprise-text{
color: var(--pink);
}
.plan-subscribe {
  box-shadow: 0 2px 20px 2px #6ed7ef;
}
.paln-box-main{
  margin-bottom: 59px !important;
}

.plan-subscribe .large-blue{
  color: var(--secondary-theme)
}

.coming-soon-opacity {
  background-color: grey;
  opacity: 0.7;
  position: absolute;
  top: 0px;
  right: 0px;
  left: 0px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.coming-text{
  font-size: 30px;
  font-weight: bold;
  opacity: 1;
  position: absolute;
  top: 0px;
  right: 0px;
  left: 0px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
p.f14-size.space_empty {
  padding: 8px;
}