li.nav-item .active {
  border-bottom: 2px solid;
  color: var(--fl-black);
  opacity: 1;
  border-radius: 2px;
}
.active .nav-link {
  color: var(--fl-black);
}
.navbar-header {
  padding: 0px;
  max-height: 61px;
}
.navbar-item-head {
  gap: 10px;
  align-items: center;
}
/* / billing-setting / */
.navbar-expand-lg .navbar-nav .notify-dropdown {
  position: absolute;
  right: 10px;
  top: 77px;
  border: 1px solid #6ED7EF;
  padding: 0px;
}
.navbar-expand-lg .navbar-nav .notify-dropdown-custom {
position: absolute;
left: -105px !important;
padding: 0px;
}
.notify-dropdown .dropdown-item-new {
padding: 3px 5px !important;
} 
.notify-profile {
  background-color: #eaf9fc;
  border: 1px solid #6ed7ef;
  border-radius: 50%;
  color: var(--pink);
  font-family: Avenir;
  font-size: 14px;
  font-style: normal;
  font-weight: 900;
  padding: 10px 8px;
  width: 42px !important;
  height: 42px !important;
  display: inline-block;
  text-align: center;
}
.profile-yellow{
  background-color:  #FFF9DF;
  border: 1px solid #FED71A;
}

.notify-dropdown .dropdown-item {
  padding: 15px 20px;
}
.notificition-drop-items.active {
  background-color: #e0f9ff !important;
  border-bottom: 0px !important;
  border-radius: 0px !important;
}
.droptrangle ul{
max-height: 300px;
height: auto;
overflow-y: auto;
}
.droptrangle ul::-webkit-scrollbar {
width: 8px;
height: 8px;
}

.droptrangle ul::-webkit-scrollbar-thumb {
background: #cacaca;
}
.notify-dropdown::after {
  content: '';
  background-image: url("../../Assets/Images/dropdown_triangle.svg");
  background-repeat: no-repeat;
  background-position: center;
  position: absolute;
  width: 50px;
  height: 40px;
  top: -30px;
  right: 0;
}

.notify-dropdown-custom::after {
  content: '';
  background-image: url("../../Assets/Images/dropdown_triangle.svg");
  background-repeat: no-repeat;
  background-position: center;
  position: absolute;
  width: 50px;
  height: 40px;
  top: -30px;
  right: -1px !important;
}
.dropdown-item-new.active, .dropdown-item-new:active {
background-color: transparent !important;
}
.dot-span {
width: 4px;
height: 4px;
display: flex;
align-items: center;
background-color: #545759;
border-radius: 50%;
margin: 0px 8px;
padding: 0;
position: relative;
top: -2px;
}

.dropnotify ul li {
  width: 200px;
  text-align: center;
  padding: 4px 14px;
}
.header-logo img{
  width: 300px;
  height: 150px;
  margin-top: -8px;
  margin-left: -47px;
}
/* General navbar styles */
.navbar-nav {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

/* Adjustments for mobile screens */
@media (max-width: 768px) {
  .navbar-collapse{
    z-index: 9999;
  }
  .navbar-nav {
    flex-direction: column;
    align-items: flex-start;
    margin: 0 !important;
    gap: 0 !important;
    padding-left: 10px;
  }
  .navbar-nav .nav-link.active{
    font-weight: bold;
  }
  .navbar-nav .nav-item {
    /* margin-bottom: 0.5rem; Adjust spacing between items */
    text-align: left;
        /* border-bottom: 1px solid #ccc; */
        /* width: 100%; */
        padding-left: 5px;
  }
  .d-mobile-dropdowns{
    position: absolute !important;
      top: 69px !important;
      right: 10px !important;
  }
}
.mobile-dropdown{
  display: flex;
}

/* Optional: Style for dropdown menus */
.dropdown-menu {
  position: absolute;
  top: 140%;
  /* left: -50px; */
  right: 0;
  z-index: 1000;
}
/* Ensure the notification dropdown is positioned relative to the trigger */
.notification-dropdown {
  position: relative;
}

.notification-dropdown-menu {
  position: absolute !important;
  top: 100%; /* Position the dropdown below the trigger */
  right: 0; /* Align the dropdown to the right side of the trigger */
  left: auto; /* Reset any left positioning */
  z-index: 1000; /* Ensure it appears above other elements */
  min-width: 200px; /* Adjust the minimum width as needed */
  /* Add more styles as necessary for appearance */
  background-color: #fff; /* Example background color */
  border: 1px solid #ddd; /* Example border color */
  border-radius: 4px; /* Example border radius */
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2); /* Example shadow */
}

/* Optional: Style for the notification icon */
.notification-dropdown .nav-link img {
  cursor: pointer;
}
