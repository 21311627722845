/* / organization-general-user step-1 / */
.join-us-section {
  background-color: #ebfbff;
}
.join-us-head h1 {
  margin-bottom: 0px;
  margin-top: 7px;
  margin: 7px 10px 0px 0px;
}
.join-text {
  color: #231f20;
  line-height: 26px;
}
.details-head .step-btn {
  border-radius: 90px;
  background: #6ed7ef;
  box-shadow: 2px 3px 0px 0px #231f20;
  padding: 8px 20px;
  justify-content: center;
  align-items: center;
  color: #000;
}
.width-drop .dropdown-menu.show {
  width: 100%;
}
.width-drop button#dropdownMenuButton1 {
  text-align: left;
  width: 100% !important  ;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.width-drop button#dropdownMenuButton1:hover {
  border-radius: 130px;
  border: 2px solid var(--black);
}
.step-one-bottom .arrow-img {
  right: 20px;
}
.leftArrow-img {
  position: absolute;
  left: 20px;
  top: 20px;
}
.back-blue-btn {
  color: var(--black);
  text-align: center;
  font-size: 20px;
  font-family: var(--font-family);
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  border-radius: 100px;
  border: 2px solid var(--fl-black);
  background: var(--secondary-theme, #6ed7ef);
  display: inline-flex;
  padding: 8px 52px 8px 52px;
}
.proceed-yellow-btn {
  color: var(--black);
  text-align: center;
  font-size: 20px;
  font-family: var(--font-family);
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  border-radius: 100px;
  border: 2px solid var(--fl-black);
  background: var(--yellow, #fed71a);
  display: inline-flex;
  padding: 8px 52px 8px 52px;
}
.back-arrow{
  position: absolute;
  top: 16px;
  left: 34px;
}
.proceed-arrow{
  position: absolute;
  top: 16px;
  right: 34px;
}

.role-custome .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input:focus {
  box-shadow: unset !important;
  border-radius: 50px !important;
}

