/* / serach-result / */
.serach-result-head {
  border-radius: 10px;
  border: 1px solid var(--blue, #6ed7ef);
  background: #ebfbff;
  padding: 25px 25px 15px 25px;
}

.active.result-category-filter-chips {
  border-radius: 90px;
  background: var(--black, #231f20);
  display: inline-flex;
  padding: 7px 20px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  color: white;
  margin: 5px;
}

.result-category-filter-chips {
  border-radius: 90px;
  border: 1px solid rgba(35, 31, 32, 0.3);
  background: #fff;
  display: inline-flex;
  padding: 7px 20px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  margin: 5px;
}

.result-category-filter-chips .result-chip-img {
  display: none;
}

.active.result-category-filter-chips .result-chip-img {
  display: block;
}

.proceed-yellow-btn {
  color: var(--black);
  text-align: center;
  font-size: 20px;
  font-family: var(--font-family);
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  border-radius: 100px;
  border: 2px solid var(--fl-black);
  background: var(--yellow, #fed71a);
  display: inline-flex;
  padding: 8px 52px 8px 52px;
}

.proceed-arrow {
  position: absolute;
  top: 16px;
  right: 34px;
}

.tapped-head-text {
  color: #231f20;
  font-family: Archivo;
  font-size: 18px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
}

.Popular-tag {
  border-radius: 90px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  background: #fff;
  display: inline-flex;
  height: 24px;
  padding: 8px 12px 7px 12px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
}

.tapped-box-main {
  border-radius: 10px;
  background: #FFF;
  box-shadow: 0px 4px 74px 0px rgba(0, 0, 0, 0.04), 0px 4px 14px 0px rgba(0, 0, 0, 0.04);
  padding: 10px;

}

.tapped-box {
  border-radius: 10px;
  background: #dff3f7;
  padding: 10px 15px;
}

.tap-company-list-item {
  display: flex;
}

.tap-label-head {
  width: 70%;
}

.tap-one-line-text {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/* / custom-check-box / */
.tap-sq-radio {
  display: block;
  position: relative;
  padding-left: 25px;
  margin: 0px 0px 0px 0px;
  cursor: pointer;
  font-size: 18px;
}

.tap-sq-radio input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.tap-cmp-head {
  position: unset;
  opacity: 0;
  cursor: pointer;
}

.tap-checkmark {
  position: absolute;
  top: -6px;
  left: 0;
  width: 14px;
  height: 14px;
  border-radius: 4px;
  background-color: white;
  border: 2px solid var(--secondary-theme);
}

.tap-checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.tap-sq-radio input:checked~.tap-checkmark:after {
  display: block;
}

.tap-sq-radio .tap-checkmark:after {
  top: 1px;
  left: 1px;
  width: 8px;
  height: 8px;
  border-radius: 2px;
  background-color: var(--secondary-theme);
}

.tap-form-check-input:checked {
  background-color: #FED71A;
  border-color: #292D32;
}

.tap-form-check-input {
  width: 18px;
  height: 18px;

}

.tap-form-check-input:checked[type=checkbox] {
  background-image: url("../../Assets/Images/check_box_tick.svg");
  background-repeat: no-repeat;
}

.colorCheck {
  background-color: #6ed7ef;
  border: none;
}

.alert-head {
  border-radius: 16px;
  background: #FED71A;
  position: relative;
  top: 8px;
  margin-left: 25px;
}

.alert-message {
  color: var(--black, #231F20);
  font-family: var(--font-family-primary);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  padding: 15px;
}

.yellow-drop {
  position: absolute;
  top: -20px;
  width: 30px;
  height: 25px;
  right: 18px;
}

.customtaps {
  margin-left: auto;
}

.tap-numbers {
  background-color: var(--yellow, #fed71a);
  margin-top: 5px;
  display: inline-block;
  padding: 5px 12px 2px 12px;
  border-radius: 20px;
  margin-bottom: 0px;
  border: 2px solid var(--fl-black);
  color: var(--black);
  font-weight: 900;
}
.taps-num {
  background-color: var(--yellow, #fed71a);
  color: var(--black);
  margin-top: 0.35em;
  margin-right: 5px;
}

.after-tapped {
  background-color: #c3c6c6
}
.after-tap-dark{
  background-color: gray;
}