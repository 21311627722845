/* / step-2 2nd-page / */
.category-step-two{
  padding-top: 100px;
 }
 .active.category-filter-chips {
   border-radius: 90px;
   background: var(--black, #231f20);
   display: inline-flex;
   padding: 7px 20px;
   justify-content: center;
   align-items: center;
   gap: 8px;
   color: white;
   margin: 5px;
 }
 .category-filter-chips {
   border-radius: 90px;
   border: 1px solid rgba(35, 31, 32, 0.30);
   background: #FFF;
   display: inline-flex;
   padding: 7px 20px;
   justify-content: center;
   align-items: center;
   gap: 8px;
   margin: 5px;
 
 }
 .category-filter-chips .chip-img{
 display: none;
 }
 .active.category-filter-chips .chip-img{
   display: block;
 }
 .back-blue-btn {
   color: var(--black);
   text-align: center;
   font-size: 20px;
   font-family: var(--font-family);
   font-style: normal;
   font-weight: 900;
   line-height: normal;
   border-radius: 100px;
   border: 2px solid var(--fl-black);
   background: var(--secondary-theme, #6ed7ef);
   display: inline-flex;
   padding: 8px 52px 8px 52px;
 }
 .proceed-yellow-btn {
   color: var(--black);
   text-align: center;
   font-size: 20px;
   font-family: var(--font-family);
   font-style: normal;
   font-weight: 900;
   line-height: normal;
   border-radius: 100px;
   border: 2px solid var(--fl-black);
   background: var(--yellow, #fed71a);
   display: inline-flex;
   padding: 8px 52px 8px 52px;
 }
 .back-arrow{
   position: absolute;
   top: 16px;
   left: 34px;
 }
 .proceed-arrow{
   position: absolute;
   top: 16px;
   right: 34px;
 }
 .icon-cross {
   position: absolute;
   top: 13px;
   right: 24px;
 }
 
 .icon-cross img {
   width: 11px;
   height: 12px;
 }
 
 
 /*-------------------------------------------------- */
 
 .toggle-switch {
   display: flex;
   align-items: center;
   justify-content: center;
 }
 
 .toggle-text {
   font-size: 14px;
   margin-right: 10px;
 }
 
 .toggle-checkbox {
   display: none;
 }
 
 .toggle-label {
   position: relative;
   width: 150px;
   height: 38px;
   background-color: #ccc;
   border-radius: 25px;
   cursor: pointer;
   transition: background-color 0.2s;
   display: inline-block;
   border: solid #CCC 1PX;
 }
 
 .toggle-label .toggle-inner {
   position: absolute;
   top: 0;
   left: 0;
   right: 0;
   bottom: 0;
   border-radius: 25px;
   background-color: white;
   display: flex;
   align-items: center;
   justify-content: space-between;
   padding: 0 5px;
   font-size: 12px;
   color: #999;
   padding: 0 26px;
   
 }
 
 .toggle-label .toggle-inner:before {
   content: "OFF";
   color: #fff;
 }
 
 .toggle-label .toggle-inner:after {
   content: "ON";
   
   
 }
 
 .toggle-label .toggle-switch {
   position: absolute;
   /* top: 2px; */
   /* left: 2px; */
   width: 50%;
   height: 36px;
   background-color: #34c759;
   border-radius: 20px;
   transition: transform 0.3s ease;
   /* padding-left: 20px; */
   color: #fff;
   font-size: 12px;
   font-weight: bold;
 }
 
 .toggle-checkbox:checked + .toggle-label {
   background-color: #34c759;
 }
 
 .toggle-checkbox:checked + .toggle-label .toggle-switch {
   transform: translateX(75px);
 }
 
 .toggle-checkbox:checked + .toggle-label .toggle-inner:before {
   color: #999;
 }
 
 .toggle-checkbox:checked + .toggle-label .toggle-inner:after {
   color: white;
 }
 
 .bg-gray{
  background: var(--bs-gray-100) !important;
 }
 
 .lable-color{
 color: #F949B2 !important;
 }
 
 .no-rap{
   text-wrap: nowrap !important;
 }