/* Sign-in-css */
.signin-main {
  height: 100vh;
}
.signin-img {
  background-color: transparent !important;
  text-align: center;
}

.eye-close {
  position: absolute;
  top: 50px;
}
.email-label {
  color: var(--black);
  font-size: 12px;
  opacity: 0.5;
}
.err_msg {
  text-align: start;
  display: block;
  padding: 5px 15px;
  color: red;
}
.signin-inner {
  width: 60%;
  margin: 0px auto;
  padding-top: 80px;
}
.arrow-img {
  position: absolute;
  top: 20px;
  right: 30px;
}
.submit-head {
  display: inline-block;
  margin: 0px auto;
}
.comm-input {
  padding: 15px 14px;
}
.email-input {
  position: absolute;
  top: -7px;
  left: 28px;
  background-color: var(--white);
  opacity: 1;
}
.forgot-link {
  color: var(--black);
  text-align: center;
  font-size: 14px;
}
.hiden-eye {
  position: absolute;
  top: 20px;
  right: 20px;
}
.cus-img-fluid {
  max-width: 100% !important;
  height: auto;
}
.sign-radius:focus {
  box-shadow: 4px 5px 0px 0px var(--pink) !important;
}
.signin-head h1 {
  font-size: 29px;
}
.reset-btn{
  padding: 10px 32px;
 }
 .signin{
  background-color: #fff !important;
 }
 .forgot-input .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 16.5px 42px 16.5px 14px !important;
}

.signin-img {
  background-color: transparent !important;
  text-align: center;
  background: url("../Images/haptapt homepage.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 100vh;
}
.logo-sign {
  position: absolute;
  bottom: 55px;
  z-index: 999;
  left: 0;
  right: 0;
  margin: auto;
}