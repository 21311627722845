/* / organization-settings / */

.setting-switch {
  display: inline-block;
}

.setting-switch-button {
  background: var(--grey);
  display: grid;
  grid-template-columns: 1fr 1fr;
  color: #000;
  position: relative;
  cursor: pointer;
  outline: 0;
  user-select: none;
  border-radius: 80px;
  border: 1px solid rgba(35, 31, 32, 0.07);
  background: #fff;
  box-shadow: 0px 4px 14px 0px rgba(0, 0, 0, 0.04);
  padding: 4px;
}

.setting-switch-input:not(:checked)+.setting-switch-button .setting-switch-button-left {
  background-color: var(--green);
  border-radius: 48px;
  color: #fff;
  text-align: center;
  font-family: Avenir;
  font-size: 14px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  display: flex;
  padding: 4px 24px;
  justify-content: center;
  align-items: center;
}

.setting-switch-input {
  display: none;
}

.setting-switch-button span {
  padding: 0.2rem 0.7rem;
  z-index: 2;
  transition: color 0.2s;
  color: var(--black, #231f20);
  text-align: center;
  font-family: Avenir;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: flex;
  justify-content: center;
  align-items: center;
}

.setting-switch-button::before {
  content: "";
  position: absolute;
  border-radius: 80px;
  top: 0;
  left: 0;
  height: 100%;
  width: 50%;
  z-index: 1;
  transition: left 0.3s cubic-bezier(0.175, 0.885, 0.32, 1), padding 0.2s ease, margin 0.2s ease;
}

.setting-switch-button:hover::before {
  will-change: padding;
}

.setting-switch-button:active::before {
  padding-right: 0.4rem;
}

.setting-switch-input:checked+.setting-switch-button .setting-switch-button-right {
  background-color: var(--green);
  border-radius: 48px;
  color: var(--white);
  text-align: center;
  font-family: Avenir;
  font-size: 14px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  display: flex;
  padding: 4px 24px;
  justify-content: center;
  align-items: center;
}

.setting-switch-input:checked+.setting-switch-button::before {
  left: 50%;
}

.setting-switch-input:checked+.setting-switch-button:active::before {
  margin-left: -0.4rem;
}

.setting-switch-input[type="checkbox"]:disabled+.setting-switch-button {
  opacity: 0.6;
  cursor: not-allowed;
}

.setting-switch-toggle-head {
  min-width: 250px;
}

.card-box-main {
  border-radius: 10px;
  background: #FFF;
  box-shadow: 0px 4px 74px 0px rgba(0, 0, 0, 0.04), 0px 4px 14px 0px rgba(0, 0, 0, 0.04);
  padding: 20px 25px;
}

.card-box-sub-head {
  min-height: 80px;
}

.card-box-component {
  border-right: 1px solid #F7F7F7;
  padding-right: 15px;
}

.white-btn {
  color: var(--black);
  text-align: center;
  font-size: 14px;
  font-family: var(--font-family);
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  border-radius: 100px;
  border: 2px solid var(--fl-black);
  background: transparent;
  display: inline-flex;
  padding: 8px 42px 8px 22px;
}

.white-arrow {
  position: absolute;
  top: 10px;
  right: 16px;
}

.card-submit-head {
  display: inline-block;
  margin: 0px auto;
}

.active.card-category-filter-chips {
  border-radius: 90px;
  background: var(--black, #231f20);
  color: white;
}

.card-category-filter-chips {
  border-radius: 90px;
  border: 1px solid rgba(35, 31, 32, 0.3);
  background: #fff;
  display: inline-flex;
  padding: 7px 20px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  margin: 5px;
  font-size: 14px;
}

.card-category-filter-chips .card-chip-img {
  display: none;
}

.active.card-category-filter-chips .card-chip-img {
  display: block;
}
.editbottom{
  margin-bottom: 40px;
}