.email-head {
    height: 100vh;
  }
  .email-text{
    color: var(--black, #231F20);
    font-family: Avenir;
    font-size: 30px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .email-link {
    color: #f9d006;
}
  .email-link:hover{
    color: var(--primary-theme);
  }
  
  