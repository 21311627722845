@font-face {
    font-family: "avenir";   /*Can be any text*/
    src: local("AvenirLTStd-Roman"),
      url("./font/AvenirLTStd-Roman.woff") format("woff");
      font-weight: 400;
      font-style: normal;
  }

  @font-face {
    font-family: "avenir";   /*Can be any text*/
    src: local("AvenirLTStd-Roman"),
      url("./font/AvenirLTStd-Medium.woff") format("woff");
      font-weight: 500;
      font-style: normal;
  }

  @font-face {
    font-family: "avenir";   /*Can be any text*/
    src: local("AvenirLTStd-Roman"),
      url("./font/AvenirLTStd-Book.woff") format("woff");
      font-weight: 400;
      font-style: normal;
  }

  /* @font-face {
    font-family: 'avenir';
    src: url('AvenirLTStd-Roman.woff2') format('woff2'),
        url('AvenirLTStd-Roman.woff') format('woff');
    font-weight:400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'avenir';
    src: url('AvenirLTStd-Book.woff2') format('woff2'),
        url('AvenirLTStd-Book.woff') format('woff');
    font-weight:400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'avenir';
    src: url('AvenirLTStd-Roman.woff2') format('woff2'),
        url('AvenirLTStd-Roman.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'avenir';
    src: url('AvenirLTStd-Medium.woff2') format('woff2'),
        url('AvenirLTStd-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
} */
/* @font-face {
    font-family: 'avenir';
    src: url('AvenirNext_Variable.ttf') format('truetype');
    font-weight:400;
    font-style: normal;
    font-display: swap;
} */

