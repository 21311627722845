@import url("https://fonts.googleapis.com/css2?family=Archivo+Black&display=swap");

:root {
  --font-family-primary: "avenir";
  --font-family-secondary: "Archivo Black", sans-serif;
  --primary-theme: #fed71a;
  --secondary-theme: #6ed7ef;
  --black: #231f20;
  --fl-black: #000;
  --white: #fff;
  --grey: #fbfaf9;
  --pink: #f949b2;
  --red: #f61919;
  --green: #00c24e;
  --toastify-color-progress-light: #f949b2;
  --toastify-color-success: #f949b2;
}

* {
  margin: 0px;
  padding: 0px;
}

body {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}

img {
  margin: 0px;
  padding: 0px;
}

html,
body {
  overflow-x: hidden;
  font-family: var(--font-family-primary) !important;
  background-color: var(--grey);
}

h1 {
  font-size: 40px;
  font-weight: 400;
  font-family: var(--font-family-secondary);
}

h2 {
  font-size: 30px;
  font-weight: 400;
  font-family: var(--font-family-secondary);
}

h3 {
  font-size: 22px;
  font-family: var(--font-family-secondary);
}

p {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 0px;
}

label {
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 0px;
}

h2,
h3,
h4,
h5,
h6,
a,
p,
li {
  word-break: break-word;
}

.fw-400 {
  font-weight: 400;
}

.fw-500 {
  font-weight: 500;
}

.fw-600 {
  font-weight: 600;
}

.fw-bold {
  font-weight: bold;
}

.fw-900 {
  font-weight: 900;
}

.f12-size {
  font-size: 12px;
}

.f13-size {
  font-size: 13px;
}

.f14-size {
  font-size: 14px;
}

.f16-size {
  font-size: 16px;
}

.f18-size {
  font-size: 18px;
}

.f20-size {
  font-size: 20px;
}

.line-24 {
  line-height: 24px;
}

.br100 {
  border-radius: 100px;
}

a {
  text-decoration: none;
}

.black-text {
  color: var(--black);
}

.white-text {
  color: var(--white);
}

.pink-text {
  color: var(--pink);
}

a:hover {
  text-decoration: none;
}

ul {
  list-style-type: none;
  padding: 0px;
  margin-bottom: 0px;
}

.yellow-back {
  background-color: var(--primary-theme);
}

.blue-back {
  background-color: var(--secondary-theme);
}

.red-text {
  color: var(--red);
}

.pink-text {
  color: var(--pink);
}

.yellow-btn {
  color: var(--black);
  text-align: center;
  font-size: 20px;
  font-family: var(--font-family);
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  border-radius: 100px;
  border: 2px solid var(--fl-black);
  background: var(--yellow, #fed71a);
  display: inline-flex;
  padding: 10px 42px;
}

.transparent-btn {
  display: inline-flex;
  padding: 8px 20px 8px 20px;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  border: 2px solid #000;
  background: transparent;
  color: var(--black, #231f20);
  text-align: center;
  font-family: Avenir;
  font-size: 14px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
}

.comm-radius {
  border-radius: 130px;
  border: 2px solid var(--black);
  background: var(--white);
}

.pink-shadow:focus {
  box-shadow: 4px 5px 0px 0px var(--pink) !important;
}

a:focus-visible {
  outline: none;
}

.form-control:focus {
  border-color: var(--black);
  box-shadow: unset;
}

.back-shadow {
  border-radius: 10px;
  background: var(--white);
  box-shadow: 0px 4px 74px 0px rgba(0, 0, 0, 0.04);
}

.form-select:focus {
  border-color: unset;
  outline: 0;
  box-shadow: unset;
}

.f11-size {
  font-size: 11px;
}

.form-check-input:focus {
  border-color: unset;
  outline: 0;
  box-shadow: unset;
}

.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input:-webkit-autofill {
  border-radius: 50px !important;
  background: transparent !important;
  fill: none !important;
}

.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input:focus {
  box-shadow: 4px 5px 0px 0px var(--pink) !important;
  border-radius: 50px !important;
}

.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input:focus {
  box-shadow: 4px 5px 0px 0px var(--pink) !important;
  border-radius: 50px !important;
}

.MuiFormControl-root.MuiFormControl-fullWidth.MuiTextField-root.css-er3gzz-MuiFormControl-root-MuiTextField-root {
  border-radius: 50px !important;
}

.MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-fullWidth.MuiInputBase-formControl.css-md26zr-MuiInputBase-root-MuiOutlinedInput-root {
  border-radius: 50px !important;
}

label[data-shrink=false]+.MuiInputBase-formControl .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
  border-radius: 50px !important;
}

input:focus-visible {
  outline: none !important;
}

.css-1bp1ao6 {
  border-radius: 50px !important;
}

.table-check {
  width: 1.3em !important;
  height: 1.3em !important;
}

.main-heigth {
  height: auto;
  min-height: calc(100vh - 294px);
}

.css-1jy569b-MuiFormLabel-root-MuiInputLabel-root {
  color: #231f20 !important;
}

.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
  color: #231f20 !important;
}

.css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root {
  color: #231f20 !important;
}

tbody {
  position: relative;
}

/* common-search-section */
.search-category input {
  border-radius: 27.5px;
  border: 2px solid var(--black, #231f20);
  background: var(--white);
  padding: 12px 22px 12px 45px;
  width: 295px;
}

.add-category-btn {
  padding: 12px 24px !important;
}

.search-icon {
  position: absolute;
  top: 16px;
  left: 21px;
  width: 18px;
  height: 18px;
}

.grey-arrow {
  position: absolute;
  top: 21px;
  right: 28px;
}

.css-1h51icj-MuiAutocomplete-root {
  width: 100% !important;
}
.css-1h51icj-MuiAutocomplete-root .MuiOutlinedInput-root {
  border-radius: 50px;
}
.custom-autocomplete label[data-shrink=false]+.MuiInputBase-formControl .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
  border: 2px solid #000 !important;
  border-radius: 50px;
}

.css-1h51icj-MuiAutocomplete-root .MuiAutocomplete-tag {
  background: #ebfbff;
}

.css-1jy569b-MuiFormLabel-root-MuiInputLabel-root {
  background: white;
}

.custom-autocomplete.MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon.css-1h51icj-MuiAutocomplete-root .MuiAutocomplete-inputRoot {
  border: 2px solid #000 !important;
}

/* .css-1ald77x {
  background: #ebfbff !important;
} */

.custom-autocomplete.MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon.css-1h51icj-MuiAutocomplete-root .MuiAutocomplete-inputRoot {
  border: 2px solid #000 !important;
}

.css-18nc3u2 {
  width: 100% !important;
}
.css-18nc3u2 .MuiOutlinedInput-root{
  border-radius: 50px;
}

.custom-autocomplete .MuiAutocomplete-inputRoot.css-segi59 {
 border:  2px solid rgb(35, 31, 32);
}
.custom-autocomplete.css-18nc3u2 .MuiOutlinedInput-root {
  border:  2px solid rgb(35, 31, 32);
}
.custom-autocomplete label[data-shrink=false]+.MuiInputBase-formControl .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
  border: 2px solid #000 !important;
  border-radius: 50px;
}
.css-18nc3u2 .MuiAutocomplete-tag {
  background: #ebfbff;
}
.css-1jy569b-MuiFormLabel-root-MuiInputLabel-root {
  background: white;
}
.custom-autocomplete .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon.css-18nc3u2 .MuiAutocomplete-inputRoot {
  border: 2px solid #000 !important;
  border-radius: 50px;
}
.custom-autocomplete.MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon.css-18nc3u2.MuiAutocomplete-inputRoot {
  border: 2px solid #000 !important;
}
.modal {
  position: fixed ;
  top: 0 ;
  left: 0 ;
  z-index:99 ;
  width: 100% ;
  height: 100% ;
  overflow-x: hidden ;
  overflow-y: auto ;
  outline: 0 ;
  display: block ;
  background-color: transparent ;
  transform: none ;
  -webkit-transform: none ;
  -moz-transform: none ;
  -ms-transform: none ;
  -o-transform: none ;
  border: 0 ;
}
.modal-dialog-width {
  max-width: 676px !important;
}
.modal-content.modal-content-custom {
  box-shadow: 0px 11px 15px -7px rgba(0,0,0,0.2),0px 24px 38px 3px rgba(0,0,0,0.14),0px 9px 46px 8px rgba(0,0,0,0.12);
  border: 1px solid #6ED7EF;
  border-radius: 20px;
}
.modal-content-custom .submit-head{
  display: flex;
  justify-content: center;
  align-items: center;

}
body{
  padding-right: 0 !important;
}
body.modal-open {
  position: fixed;
  width: 100%;
}
.css-p0rm37.Mui-error {
  color: rgba(0, 0, 0, 0.6) !important;
}
.css-1bp1ao6.Mui-error .MuiOutlinedInput-notchedOutline {
  border-color: rgb(35, 31, 32) !important;
}
.css-qiwgdb.css-qiwgdb.css-qiwgdb{
  text-align: left !important;
}

.css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.Mui-checked, .css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.MuiCheckbox-indeterminate {
  color: #ffd518 !important;
}