.footer-main {
    background-color: var(--black);
    padding: 60px 0px;
    position:relative;
    bottom: 0;
    width: 100%;
    margin-top: 30px;
}

.footer-logo-text {
    color: var(--white);
    font-size: 12px !important;
    font-weight: 300 !important;
}

.footer-subhead h4 {
    font-weight: 600;
    font-size: 20px;
    margin-bottom: 15px;
}
.footer-sub-text{
    color: var(--white);
}
.footer-icons{
    max-width: 100%;
    height: auto;
}
.footer-hover:hover {
    color: var(--primary-theme);
}
.custom-offset{
margin-right:8.33%;
}