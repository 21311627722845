.modal-video{
    max-width: 100%;
    height: auto;
    border-radius: 15px;
}
.intro-head{
    font-size: 40px !important;
    margin-right: 6px;
}
.css-1ftxpfd {
    width: 976px !important;
}
