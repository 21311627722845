.signin-main {
  height: 100vh;
}
.signin-img {
  background-color: #ebfbff;
  text-align: center;
}
.eye-close {
  position: absolute;
  top: 50px;
}
.err_msg {
  text-align: start;
  display: block;
  padding: 5px 15px;
  color: red;
}
.email-label {
  color: var(--black);
  font-size: 12px;
  opacity: 0.5;
}
.signin-inner {
  width: 60%;
  margin: 0px auto;
  padding-top: 150px;
}
.arrow-img {
  position: absolute;
  top: 20px;
  right: 30px;
}
.submit-head {
  display: inline-block;
  margin: 0px auto;
}
.comm-input {
    padding: 15px 14px;
}
.email-input {
  position: absolute;
  top: -7px;
  left: 28px;
  background-color: var(--white);
  opacity: 1;
}
.forgot-link {
  color: var(--black);
  text-align: center;
  font-size: 14px;
  cursor: pointer;
}
.forgot-link:hover {
  color: var(--black);
}
.hiden-eye {
  position: absolute;
  top: 20px;
  right: 20px;
}
.cus-img-fluid {
  max-width: 98%;
  height: 100%;
}
.forgot-radius:focus {
  box-shadow: 4px 5px 0px 0px var(--pink) !important;
}
.signin{
  background-color: #fff !important;
 }
 .forgot-input .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 16.5px 42px 16.5px 14px !important;
}
.forgot-input .css-1x5jdmq {
  padding: 16.5px 42px 16.5px 14px !important;
}
.forgetimg {
  background-image: none;
  background-color: #ebfbff !important;
}