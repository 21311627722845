/* organization-section */
.organization-table {
  border-radius: 10px;
}

.organization-table {
  min-height: 300px;
}

.organization-table tr {
  vertical-align: middle;
}
.organization-table thead tr {
  background: var(--black, #231f20);
  color: var(--white);
  font-size: 14px;
  font-style: normal;
  font-weight: 850;
}
.organization-table tbody tr {
  color: var(--black, #231f20);
  font-size: 14px;
  font-weight: 500;
}
.table-content {
  margin-right: 10px;
}
.table-content-item {
  border-radius: 90px;
  border: 1px solid rgba(35, 31, 32, 0.3);
  background: var(--white);
  padding: 4px 16px;
  display: inline-block;
  margin-bottom: 5px;
  margin-right: 5px;
  font-size: 11px;
}
.red-due-msg {
  color: var(--red);
  font-size: 12px;
  font-weight: 900;
}
tr.active {
  background-color: #ebfbff;
  border: 2px solid black;
}

.active .table-content-item {
  background-color: var(--black);
  color: var(--white);
}
.pink-more-text {
  color: var(--pink);
  font-size: 11px;
  font-weight: 800;
}
.pagination-right {
  display: flex;
  justify-content: end;
}
.custom-pagination {
  display: flex;
  align-items: center;
}
.custom-pagination .page-item {
  padding-right: 10px;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}
.custom-pagination .cus-page-item {
  padding-right: 5px;
}
.custom-pagination .cus-page-link {
  padding: 3px 8px 0px 7px;
}
.custom-pagination .active .cus-page-link {
  border-radius: 100%;
  padding: 2px 8px 0px 8px;
  background: var(--black);
  color: var(--white);
  text-align: center;
  vertical-align: middle;
}
.notOrganization {
  font-size: 25px;
  font-family: avenir;
  text-align: center;
  margin: 50px auto;
}
.err_msg {
  text-align: start;
  display: block;
  padding: 5px 15px;
  color: red;
}
.searchCross {
  position: absolute;
  top: 20px;
  right: 10px;
  width: 10px;
  height: 10px;
}
.pagination-number.active {
  background: #000;
  border-radius: 50%;
  margin: 2px;
  width: 26px;
  height: 26px;
  color: #fff;
  padding: 3px 9px;
}
.pagination-number {
  background: transparent;
  color: #000;
  padding: 3px 9px;
  font-size: 14px;
}

.manage-sub-btn {
  font-size: 14px !important;
  font-weight: 500 !important;
  padding: 8px !important;
}
.css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root {
  font: inherit;
  letter-spacing: inherit;
  color: currentColor;
  border: 0;
  box-sizing: content-box;
  background: none;
  margin: 0;
  -webkit-tap-highlight-color: transparent;
  display: block;
  min-width: 0;
  width: 100%;
  -webkit-animation-name: mui-auto-fill-cancel;
  animation-name: mui-auto-fill-cancel;
  -webkit-animation-duration: 10ms;
  animation-duration: 10ms;
}

.custom-date-input fieldset {
  border: 2px solid #231f20 !important;
  border-radius: 30px;
}
.css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root:focus > input {
  box-shadow: 4px 5px 0px 0px var(--pink) !important;
  border-radius: 50px !important;
}

.css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root > input:focus {
  box-shadow: none !important;
  border-radius: 50px !important;
}

.css-1jy569b-MuiFormLabel-root-MuiInputLabel-root.Mui-focused {
  color: #231f20 !important;
}

.manage-btn{
  background-color: grey !important;
  cursor: not-allowed !important;
}
.custom-date-input .MuiSvgIcon-fontSizeMedium{
  display: block;

}
.custome-message-detail{
    max-height: 500px;
    overflow-y: auto;
}
.upload-icon {
  height: 40px;
  width: 40px;
  margin-right: 2px;
}
.upload-input {
  position: absolute;
  top: 5px;
  right: 3px;
  width: 40px;
  opacity: 0;
}
.upload-search input{
  width: 220px !important;
  padding: 10px 20px 10px 45px !important;
}
.action-details:hover {
  color: var(--black) important;
}
