@media screen and (max-width: 1600px) and (min-width: 1200px) {
  .grey-arrow {
    position: absolute;
    right: 6px !important;
    top: 15px;
  }

  .transparent-btn {
    padding: 8px 28px 8px 9px;
    font-size: 13px;
  }

  .search-category input {
    padding: 10px 45px 10px 45px;
  }

  .cus-grey-arrow {
    position: absolute !important;
    top: 16px !important;
    right: 20px !important;
  }

  .new-cus-grey-arrow {
    position: absolute !important;
    top: 14px !important;
    right: 7px !important;
  }

  .reset-arrow {
    position: absolute;
    top: 16px !important;
    right: 20px !important;
  }

  .req-btn {
    position: absolute;
    top: 14px !important;
    right: 20px !important;
  }

  .tap-img {
    position: absolute;
    top: 19px;
    right: 20px;
  }

}

@media screen and (min-width:1200px) and (max-width:1560px) {
  .label-profile {
    padding-left: 2px;
    display: block;
  }

  .label-profile .one-line-text {
    width: 100%;
    overflow: auto;
    text-overflow: inherit;
    white-space: normal;
  }

  .profile-org-descr,
  .profile-org-head {
    width: 100%;
  }

  .text-cus-align {
    text-align: left;
  }

  .company-list-cus {
    display: block;
  }

  .company-list-cus .one-line-text {
    width: 100%;
    overflow: visible;
    text-overflow: initial;
    white-space: normal;
  }
}

@media screen and (max-width: 1600px) {
  .category-step-two {
    padding-top: 60px;
  }

  .tap-sq-radio .tap-checkmark:after {
    left: 0.5px;
    top: 0.5px;
  }

  .back-arrow {
    position: absolute;
    top: 10px;
    left: 34px;
  }

  .proceed-arrow {
    position: absolute;
    top: 10px;
    right: 34px;
  }

  .number-text {
    font-size: 28px;
  }

  .add-card {
    padding: 120px 110px;
  }

  .tap-img {
    position: absolute;
    top: 19px;
    right: 20px;
  }

  .add-category-btn {
    padding: 10px 22px !important;
  }

  .search-icon {
    position: absolute;
    top: 14px;
    left: 21px;
  }


  .searchCross {
    position: absolute;
    top: 18px;

  }

  .billing-dropdown {
    display: inline-flex;
    padding: 10px 42px 10px 30px;
  }

  .drop-img {
    position: absolute;
    top: 16px;
    right: 24px;
  }

  .custom-catgory-btn {
    padding: 10px 22px !important;
  }

  .info-btn-icon {
    position: absolute;
    top: 11px;
    right: 12px;
  }

  .white-btn {
    padding: 8px 20px 8px 20px !important;
  }

  .company-info-btns .add-category-btn {
    padding: 10px 10px !important;
  }

  .role-list {
    padding: 7px 12px;
  }

  .modal-video {
    max-width: 90%;
    height: auto;
    border-radius: 15px;
  }

  .css-1ald77x {
    background-color: var(--grey);
    padding: 0px 4px !important;
  }

  .modal-head .css-1ald77x {
    background-color: #fff;
  }

  .signin-form .css-1ald77x {
    background-color: #fff;
  }
}

@media screen and (max-width: 1400px) {

  .member-table th,
  td {
    padding: 8px 8px !important;
  }

  .organization-table th {
    padding: 8px 8px !important;
  }

  /* .grey-arrow {
    position: absolute;
    top: 16px;
    right: 20px;
  } */

  .sq-radio {
    padding-left: 20px;
  }

  .label-head {
    width: 100%;
  }

  .side-yellow-btn {
    padding: 10px 32px 10px 22px !important;
  }

  .info-btn-icon {
    position: absolute;
    right: 10px !important;
  }

  .plan-btn {
    padding: 8px 20px 8px 20px;
  }

  .plan-arrow {
    position: absolute;
    top: 16px;
    right: 15px;
  }

  .add-card {
    padding: 115px 90px;
  }

  .yellow-change-btn {
    padding: 10px 10px !important;
  }

  .sq-radio {
    margin: 0px 0px 0px 0px;
  }

  .paln-box-main {
    width: 314px;
    margin: 0 auto;
  }

  .css-1ftxpfd {
    width: 720px !important;
  }
}

@media screen and (max-width: 1200px) {
  .add-catgeory-btn {
    padding: 14px 35px !important;
  }

  .cus-grey-arrow {
    position: absolute !important;
    top: 20px !important;
    right: 15px !important;
  }

  .search-category input {
    padding: 10px 45px 10px 45px;
    width: 240px;
  }

  .upload-search input {
    width: 200px !important;
    padding: 10px 10px 10px 45px !important;
  }

  .new-cus-grey-arrow {
    position: absolute !important;
    top: 19px !important;
    right: 16px !important;
  }

  .signin-inner {
    width: 80%;
    margin: 0px auto;
    padding-top: 35px;
  }

  .reset-arrow {
    position: absolute;
    top: 16px !important;
    right: 20px !important;
  }

  .req-btn {
    position: absolute;
    top: 14px !important;
    right: 20px !important;
  }

  .css-1ftxpfd {
    width: 876px !important;
  }

  .category-list {
    margin-top: 15px;
  }
}

@media(min-width:992px) and (max-width:1300px) {
  .signin-inner {
    padding-top: 44px;
  }
}

@media(min-width:1301px) {
  .signin-inner {
    padding-top: 24px;
  }
}

@media(min-width:1900px) {
  .signin-inner {
    padding-top: 150px;
  }
}

@media screen and (max-width: 991px) {
  .signin-img {
    display: none;
  }

  .signin-inner {
    width: 50%;
    margin: 0px auto;
    padding-top: 35px;
  }

  .reset-arrow {
    position: absolute;
    top: 16px !important;
    right: 20px !important;
  }

  .signin-main {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .add-card {
    padding: 132px 45px;
  }

  .navbar-expand-lg .navbar-nav .notify-dropdown {
    position: absolute;
    left: -280px;
  }

  .css-1ftxpfd {
    width: 95% !important;
    margin: 0px auto;
  }

  .notify-dropdown::before {
    right: 59px;
  }

  .navbar-item-head {
    /* gap: 10px;
    align-items: center;
    flex-direction: inherit;
    justify-content: space-between; */
  }

  /* .footer-main{
    position: relative;
   } */
  .main-heigth {
    height: auto;
    min-height: calc(100vh - 408px);
  }

  .notify-text {
    line-break: auto;
    width: 32ch;
    overflow: hidden;
    white-space: initial;
  }

  .notify-dropdown::after {
    right: -5px;
  }

  .dropnotify {
    position: absolute;
    left: -100px !important;
    width: 200px !important;
  }

  .dropnotify ul {
    max-height: 300px;
    height: auto;
    overflow-y: unset;
  }
}

@media screen and (max-width: 767px) {
  .dropnotify {
    position: absolute !important;
    left: -98px !important;
  }

  .search-category input {
    padding: 13px 30px 13px 40px;
  }

  .yellow-btn {
    font-size: 16px;
    padding: 10px 32px;
  }

  .grey-arrow {
    position: absolute;
    top: 16px;
    right: 15px;
  }

  .info-btn-icon {
    position: absolute;
    right: 10px;
  }

  .search-category input {
    padding: 10px 30px 10px 40px;
  }

  .member-table th,
  td {
    padding: 8px 8px !important;
    width: 100%;
    min-width: 149px;
  }

  .cus-grey-arrow {
    position: absolute !important;
    top: 17px !important;
    right: 16px !important;
  }

  .table-content-item {
    padding: 4px 16px;
    text-align: center;
  }

  .billing-dropdown {
    display: inline-flex;
    padding: 10px 40px 10px 15px;
  }

  .new-cus-grey-arrow {
    position: absolute !important;
    top: 15px !important;
    right: 16px !important;
  }

  .signin-inner {
    width: 64%;
  }

  .reset-btn {
    font-size: 20px;
    padding: 8px 60px;
  }

  .reset-arrow {
    position: absolute;
    top: 14px !important;
    right: 20px !important;
  }

  .modal-head {
    width: 100% !important;
  }

  .req-btn {
    position: absolute;
    top: 13px !important;
    right: 12px !important;
  }

  .drop-img {
    position: absolute;
    top: 16px;
    right: 18px;
  }

  .tap-img {
    position: absolute;
    top: 15px;
    right: 30px;
  }

  .add-category-btn {
    padding: 13px 20px !important;
  }

  .custom-catgory-btn {
    padding: 11px 20px !important;
    font-size: 18px !important;
  }

  .css-1ftxpfd {
    width: 95% !important;
    margin: 0px auto;
  }

  .App {
    margin-left: 10%;
    margin-top: 10%;
    margin-right: 10%;
    margin-bottom: 10%;
  }

  .category-step-two {
    padding-top: 50px;
  }

  .navbar-expand-lg .navbar-nav .notify-dropdown {
    position: absolute;
    left: -285px;
  }

  .notify-dropdown::before {
    right: 54px;
  }

  .footer-main {
    padding: 30px 0px;
  }

  .main-heigth {
    height: auto;
    min-height: calc(100vh - 348px);
  }
}

@media (min-width:568px) and (max-width:768px) {
  .profile-org-head {
    width: 60%;
  }
}

@media screen and (max-width: 567px) {
  .upload-input {
    top: 15px;
    left: 0px;
  }

  .upload-icon {
    margin-top: 10px;
  }

  .sub-company-list-item {
    display: block;
    margin-bottom: 12px;
  }

  .label-profile {
    padding-left: 21px;
    display: block;
  }

  .text-cus-align {
    text-align: left;
  }

  .label-profile .one-line-text {
    width: 100%;
    overflow: initial;
    text-overflow: inherit;
    white-space: normal;
  }

  .search-category input {
    padding: 10px 32px 10px 40px;
  }

  .cus-search-category {
    width: unset;
  }

  .custom-pagination {
    display: flex;
    margin: 6px;
  }

  .signin-inner {
    width: 90%;
  }

  .billing-dropdown {
    padding: 10px 32px 10px 12px;
  }

  .billing-main-drop {
    width: 226px;
  }

  .drop-img {
    position: absolute;
    right: 14px;
  }

  .notMember {
    margin: 15px auto;
  }

  .search-category {
    width: 247px;
  }

  .searchCross {
    position: absolute;
    top: 18px;
    right: 20px;
  }

  .email-text {
    font-size: 22px;
  }

  .payment-form-main {
    padding: 20px;
    width: auto;
  }

  .App {
    margin-left: 5%;
    margin-top: 5%;
    margin-right: 5%;
    margin-bottom: 5%;
  }

  .number-element {
    padding: 12.5px 14px !important;
  }

  .css-1ald77x {
    position: absolute;
    left: 2px !important;
    top: 2px !important;
  }

  .footer-icons {
    max-width: 20%;
    height: auto;
  }

  .footer-main {
    margin-top: 20px;
  }

  .main-heigth {
    height: auto;
    min-height: calc(100vh - 420px);
  }

  .tutorial-video {
    max-width: 80%;
    height: auto;
  }

  .css-1ald77x {
    padding: 0px 3px !important;
  }

  .profile-org-descr,
  .profile-org-head {
    width: 100%;
  }

  .company-list-cus .one-line-text {
    width: 100%;
    overflow: visible;
    text-overflow: initial;
    white-space: normal;
  }
}

@media screen and (max-width: 420px) {
  .navbar-expand-lg .navbar-nav .dropnotify {
    position: absolute !important;
    left: -66px !important;
    top: 68px !important;
  }

  .dropnotify::after {
    /* right: 86px !important; */
    right: 53px !important;
  }

  .add-catgeory-btn {
    padding: 14px 20px !important;
    font-size: 14px !important;
  }

  .cus-grey-arrow {
    position: absolute !important;
    top: 17px !important;
    right: 4px !important;
  }

  .custom-catgory-btn {
    padding: 12px 24px !important;
    font-size: 16px !important;
  }

  .custom-transparent {
    padding: 8px 15px 8px 15px;
  }

  .signin-inner {
    width: 95%;
  }

  .forgot-head h1 {
    font-size: 24px;
  }

  .comm-input {
    padding: 11px 20px;
  }

  .hiden-eye {
    position: absolute;
    top: 17px;
    right: 20px;
  }

  .back-blue-btn {
    padding: 6px 22px 6px 42px;
  }

  .back-arrow {
    position: absolute;
    top: 10px;
    left: 18px;
  }


  .proceed-arrow {
    position: absolute;
    top: 10px;
    right: 18px;
  }

  .card-submit-head {
    display: block !important;
  }

  .card-submit-head .white-btn {
    margin-bottom: 10px;
  }

  .credit-payment-text {
    color: var(--white);
    font-size: 25px;
  }

  .number-text {
    font-size: 20px;
  }

  .valid-number {
    font-size: 16px;
  }

  .customer-name {
    font-size: 18px;
  }

  .email-text {
    font-size: 18px;
  }

  .footer-subhead h4 {
    font-weight: 600;
    font-size: 18px;
    margin-bottom: 10px;
  }

  .footer-subhead p {
    font-size: 14px;
  }

  .notify-text {
    width: 18ch;
    white-space: normal;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  .navbar-expand-lg .navbar-nav .notify-dropdown {
    position: absolute;
    left: -113px;
  }

  .notify-dropdown::before {
    right: 105px;
  }

  .navbar-item-head {
    align-items: flex-start;
    flex-direction: column;
    gap: 10px;
    justify-content: space-between;
  }

  .category-step-two {
    padding-top: 50px;
  }

  .footer-main {
    padding: 15px 0px;
    margin-top: 20px;
  }

  .main-heigth {
    height: auto;
    min-height: calc(100vh - 358px);
  }

  .setting-switch-input:not(:checked)+.setting-switch-button .setting-switch-button-left {
    padding: 4px 10px;
  }

  .navbar-expand-lg .navbar-nav .notify-dropdown {
    position: absolute;
    right: unset;
  }
  .notify-dropdown::after {
    right: -12px;
  }

  .notify-dropdown .dropdown-item {
    padding: 10px 10px;
  }

  .notify-text {
    width: 20ch;
    white-space: pre-line;
    overflow: unset;
    text-overflow: unset;
    display: unset;
    -webkit-line-clamp: unset;
    -webkit-box-orient: unset;
  }

  .notify-text p {
    font-size: 14px;
  }
}

@media screen and (max-width: 380px) {
  .App {
    margin-left: 2%;
    margin-top: 2%;
    margin-right: 2%;
    margin-bottom: 2%;
  }

  .payment-form-main {
    padding: 10px;
    width: auto;
  }

  .paln-box-main {
    width: 100%;
    margin: 0 auto;
  }

  .footer-head-img {
    margin-top: 0.35rem;
  }

  .switch-delete-acc {
    display: block !important;
  }

  .pagination-container-cust .pagination-item-cust {
    margin: auto 0px;
  }

  .custom-pagination .page-item {
    padding-right: 2px;
  }

}

@media screen and (max-width: 320px) {
  .footer-head-img {
    margin-top: 0.55rem;
  }

  .footer-icons {
    max-width: 25%;
    height: auto;
  }

  .pagination-container-cust .pagination-item-cust {
    padding: 0 0px;
    height: 25px;
    min-width: 25px;
    width: 25px;
  }

}

@media(min-width:350px) and (max-width:576px) {
  .action-details {
    padding: 10px 20px;
    width: 30%;
  }
}

/* @media(min-height:400px) and (max-height:680px) {
  .editbottom {
    margin-bottom: 20px;
  }

  .modal-head .comm-radius {
    height: 44px;
  }


  .modal-head .css-1x5jdmq {
    padding: 10.5px 48px 10.5px 10px !important
  }

  .modal-head .css-p0rm37 {
    top: -5px !important;
  }

  .modal-head .forgot-input .hiden-eye {
    top: 14px;
  }
}

@media(min-height:300px) and (max-height:400px) {
  .editbottom {
    margin-bottom: 17px;
  }

  .modal-head .comm-radius {
    height: 34px;
  }

  .modal-head .css-1x5jdmq {
    padding: 10.5px 48px 10.5px 10px !important
  }

  .modal-head .css-p0rm37 {
    top: -5px !important;
  }

  .modal-head .forgot-input .hiden-eye {
    top: 14px
  }
} */