.found-head {
    height: 100vh;
  }
  .found-text{
    color: var(--black, #231F20);
    font-family: Avenir;
    font-size: 30px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }