
/*category-page */
.list-category-main {
    border-radius: 10px;
    background: var(--white);
    box-shadow: 0px 4px 74px 0px rgba(0, 0, 0, 0.05);
    padding: 14px 22px;
    border-left: 10px solid var(--secondary-theme);
  }
  .list-category-head p {
    font-weight: 900;
  }
  .list-content {
    margin-right: 10px;
  }
  .err_msg {
    text-align: start;
    display: block;
    padding: 5px 15px;
    color: red;
}
  .notCategory {
    font-size: 25px;
    text-align: center;
    margin: 50px 50px;
    font-family: avenir;
  }
  .list-content-item {
    border-radius: 90px;
    border: 1px solid rgba(35, 31, 32, 0.3);
    background: var(--white);
    padding: 6px 20px;
    display: inline-block;
    margin-right: 10px;
  }
  .list-category-right {
    gap: 10px;
    display: flex;
  }
  .list-category-right img {
    border-radius: 38px;
    border: 1px solid #231f202b;
    padding: 8px;
  }
  
  .list-category-main {
    margin-bottom: 20px;
  }
  .category-list {
    margin-top: 44px;
}

  .css-1jy569b-MuiFormLabel-root-MuiInputLabel-root.Mui-focused{
    color: #231f20 !important;
  }

.css-er3gzz-MuiFormControl-root-MuiTextField-root fieldset {
  border: 2px solid #231f20 !important;
  border-radius: 30px;
}
.css-1h51icj-MuiAutocomplete-root .MuiOutlinedInput-root {
  padding: 0px !important;
}
.css-1h51icj-MuiAutocomplete-root .MuiOutlinedInput-root .MuiAutocomplete-input {
  padding: 16.5px 14px !important;
}