.pagination-container-cust {
  display: flex;
  list-style-type: none;
}
.pagination-container-cust .pagination-item-cust {
  padding: 0 5.6px;
  height: 30px;
  text-align: center;
  margin: auto 4px;
  color: rgba(0, 0, 0, 0.87);
  display: flex;
  box-sizing: border-box;
  align-items: center;
  letter-spacing: 0.01071em;
  border-radius: 100%;
  font-size: 14px;
  min-width: 30px;
  justify-content: center;
  width: 30px;
}
.pagination-container-cust .pagination-item-cust.dots-cust:hover {
  background-color: transparent;
  cursor: default;
}
.pagination-container-cust .pagination-item-cust:hover {
  background-color: #000;
  color: #fff;
  cursor: pointer;
}
.pagination-container-cust .pagination-item-cust.selected {
  background-color: #000;
  color: #fff;
}
.pagination-container-cust .pagination-item-cust.disabled {
  pointer-events: none;

}
.pagination-container-cust .pagination-item-cust.disabled:hover {
  background-color: transparent;
  cursor: default;
}