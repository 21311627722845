.organization-table {
  border-radius: 10px;
  background: var(--white);
  box-shadow: 0px 4px 74px 0px rgba(0, 0, 0, 0.04);
}

.organization-table tr {
  vertical-align: middle;
}
.organization-table thead tr {
  background: var(--black, #231f20);
  color: var(--white);
  font-size: 14px;
  font-style: normal;
  font-weight: 850;
}
.err_msg {
  text-align: start;
  display: block;
  padding: 5px 15px;
  color: red;
}
.organization-table tbody tr {
  color: var(--black, #231f20);
  font-size: 14px;
  font-weight: 500;
}
.table-content {
  margin-right: 10px;
}
.table-content-item {
  border-radius: 90px;
  border: 1px solid rgba(35, 31, 32, 0.3);
  background: var(--white);
  padding: 4px 16px;
  display: inline-block;
  margin-bottom: 8px;
  font-size: 11px;
}
.red-due-msg {
  color: var(--red);
  font-size: 12px;
  font-weight: 900;
}
.organization-table tr.active td:first-child {
  color: var(--pink);
}
tr.active {
  background-color: #ebfbff;
  border: 2px solid black;
}

.active .table-content-item {
  background-color: var(--black);
  color: var(--white);
}
.pink-more-text {
  color: var(--pink);
  font-size: 11px;
  font-weight: 800;
}
.pagination-right {
  display: flex;
  justify-content: end;
}
.custom-pagination {
  display: flex;
}
.custom-pagination .page-item {
  padding-right: 10px;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}
.custom-pagination .cus-page-item {
  padding-right: 5px;
}
.custom-pagination .cus-page-link {
  padding: 3px 8px 0px 7px;
}
.custom-pagination .active .cus-page-link {
  border-radius: 100%;
  padding: 2px 8px 0px 8px;
  background: var(--black);
  color: var(--white);
  text-align: center;
  vertical-align: middle;
}
.logo-img {
  height: 80px;
}

/* member-detail-head */

/* Button Group Switch*/
.switch {
  display: inline-block;
}
.switch-button {
  background: var(--grey);
  display: grid;
  grid-template-columns: 1fr 1fr;
  color: #000;
  position: relative;
  cursor: pointer;
  outline: 0;
  user-select: none;
  border-radius: 80px;
  border: 1px solid rgba(35, 31, 32, 0.07);
  background: #fff;
  box-shadow: 0px 4px 14px 0px rgba(0, 0, 0, 0.04);
  padding: 4px;
}

.switch-input:not(:checked) + .switch-button .switch-button-left {
  background-color: var(--red);
  border-radius: 48px;
  color: #fff;
  text-align: center;
  font-family: Avenir;
  font-size: 14px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  display: flex;
  padding: 4px 24px;
  justify-content: center;
  align-items: center;
}

.switch-input {
  display: none;
}

.switch-button span {
  padding: 0.2rem 0.7rem;
  z-index: 2;
  transition: color 0.2s;
  color: var(--black, #231f20);
  text-align: center;
  font-family: Avenir;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.switch-button::before {
  content: "";
  position: absolute;
  border-radius: 80px;
  top: 0;
  left: 0;
  height: 100%;
  width: 50%;
  z-index: 1;
  transition: left 0.3s cubic-bezier(0.175, 0.885, 0.32, 1), padding 0.2s ease,
    margin 0.2s ease;
}

.switch-button:hover::before {
  will-change: padding;
}

.switch-button:active::before {
  padding-right: 0.4rem;
}

.switch-input:checked + .switch-button .switch-button-right {
  background-color: var(--green);
  border-radius: 48px;
  color: var(--white);
  text-align: center;
  font-family: Avenir;
  font-size: 14px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  display: flex;
  padding: 4px 24px;
  justify-content: center;
  align-items: center;
}

.switch-input:checked + .switch-button::before {
  left: 50%;
}
.switch-input:checked + .switch-button:active::before {
  margin-left: -0.4rem;
}

.switch-input[type="checkbox"]:disabled + .switch-button {
  opacity: 0.6;
  cursor: not-allowed;
}
.delete-acc-link {
  color: var(--black, #231f20);
  text-align: center;
  font-family: Avenir;
  font-size: 14px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
}
.switch-toggle-head {
  min-width: 250px;
}

.billing-dropdown {
  display: inline-flex;
  padding: 12px 38px 12px 26px;
  justify-content: center;
  align-items: center;
  gap: 12px;
  border-radius: 27.5px;
  border: 2px solid var(--black, #231f20);
  background: #fff;
  color: var(--black, #231f20);
  font-family: Avenir;
  font-size: 20px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
}
.switch-button-grey{
  background-color: grey !important;
}
.switch-input:checked + .switch-button-grey .switch-button-right{
  background-color: lightgray !important;
}

/* company-info */
.company-info-head {
  font-size: 24px;
}
.company-section-head {
  border-bottom: 1px solid var(--secondary-theme);
}
.company-info-sub-head {
  color: #343a40;
  font-size: 18px;
}
.sub-company-list-item {
  display: flex;
  margin-bottom: 5px;
}
.company-info-btns {
  display: inline-block;
}
.info-btn-icon {
  position: absolute;
  top: 12px;
  right: 15px;
}
.role-list {
  border-radius: 283px;
  border: 1px solid rgba(35, 31, 32, 0.3);
  background: var(--secondary-theme);
  padding: 7px 15px;
  display: inline-block;
  margin-bottom: 10px;
  margin-right: 10px;
  font-size: 14px;
}
.member-table th,
td {
  padding: 10px 15px !important;
}
.organization-table th{
  padding: 10px 15px !important;
}
.member-head {
  font-size: 22px;
}
.home-nav-link {
  color: var(--black);
}
.home-nav-link.active {
  color: unset !important;
  background-color: unset !important;
  border-color: unset !important;
  border: none !important;
  border-radius: 4px 4px 0px 0px;
  border-bottom: 4px solid var(--yellow);
}
.home-nav-link:hover {
  border-color: transparent !important;
}
.home-nav-link.active {
  border-bottom: 6px solid #fed71a !important;
}
.custom-nav-tab {
  border-bottom: 1px solid var(--primary-theme);
}
.new-add-category {
  display: inline-block;
}
.new-search-category {
  display: inline-block;
}
.item-paid {
  color: var(--green, #00c24e) !important;
  font-family: Avenir;
  font-size: 11px;
  font-style: normal;
  font-weight: 500;
  border-radius: 90px;
  border: 1px solid rgba(0, 194, 78, 0.3);
  background: rgba(0, 194, 78, 0.07) !important;
}
.organization-table .active .item-paid {
  background: var(--green, #00c24e) !important;
  color: var(--white) !important;
}

.item-failed {
  color: var(--red, #f61919) !important;
  font-family: Avenir;
  font-size: 11px;
  font-style: normal;
  font-weight: 500;
  border-radius: 90px;
  border: 1px solid rgba(246, 25, 25, 0.3);
  background: rgba(246, 25, 25, 0.07) !important;
}
.organization-table .active .item-failed {
  background: var(--red, #f61919) !important;
  color: var(--white) !important;
}
.notOrganization {
  font-size: 25px;
  margin: 50px auto;
  font-family: avenir;
  text-align: center;
}
.notMember {
  font-size: 25px;
  text-align: center;
  margin: 50px auto;
  font-family: avenir;
}
.grey-arrow {
  position: absolute !important;
  top: 19px !important;
  right: 20px !important;
}
.arrow-img {
  position: absolute;
  top: 15px;
  right: 20px;
}
/* thin-scrollbar */
.thin-scroll {
  width: 100%;
  overflow: auto;
  overflow-y: hidden;
}
.thin-scroll::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.thin-scroll::-webkit-scrollbar-track {
  -webkit-border-radius: 5px;
  border-radius: 5px;
}

.thin-scroll::-webkit-scrollbar-thumb {
  -webkit-border-radius: 5px;
  border-radius: 5px;
  background: rgba(255, 255, 255, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}

.drop-img {
  position: absolute;
  top: 21px;
  right: 22px;
}
.custom-catgory-btn{
  padding:12px 24px !important;
}
.custom-transparent{
  background-color: var(--grey) !important;
}
.switch-input:not(:checked) + .switch-button-grey .switch-button-left {
  background-color: lightgray !important;
}


.label-profile{
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.text-cus-align{
  text-align: right;
}

/* new */
.company-list-cus {
  margin-bottom: 5px;
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.profile-org-descr {
  width: 64%;
}
.profile-org-head {
  width: 36%;
}
.label-profile .one-line-text,.company-list-cus .one-line-text {
  width: 100%;
  overflow: visible;
  text-overflow: initial;
  white-space: normal;
}
.edit-org-icon {
  width: 15px;
  height: 15px;
  object-fit: contain;
}
